import { useEffect } from 'react'
import classNames from 'classnames'

import useAppConfig from 'lib/network/hooks/useAppConfig'
import useSession from 'lib/network/hooks/useSession'
import FacebookConnectButton from 'components/core/FacebookConnectButton'
import GoogleConnectButton from 'components/core/GoogleConnectButton'
import setUpFacebook from 'components/core/FacebookConnectButton/setUpFacebook'
import setUpGoogle from 'components/core/GoogleConnectButton/setUpGoogle'
import styles from './styles.module.scss'

const SocialConnectButtons = ({
  handleError = null,
  handleSuccess = null,
  siteLocation,
  wrapperClassName = null,
  buttonClassName = null,
  iconOnly = false,
}) => {
  const { data: appConfig } = useAppConfig()
  const { googleAuthApiKey: googleKey, facebookApiKey: facebookKey } = appConfig ?? {}
  const { data: session } = useSession()
  const tokenState = session?.omniauthToken

  useEffect(() => {
    setUpGoogle()
    if (facebookKey) setUpFacebook(facebookKey)
  }, [facebookKey])

  if (!tokenState) return null

  return (
    <div className={classNames(styles['social-connect'], wrapperClassName)}>
      <GoogleConnectButton
        className={classNames(styles['social-connect__button'], buttonClassName, {
          [styles['social-connect__button--icon-only']]: iconOnly,
        })}
        siteLocation={siteLocation}
        onSuccess={handleSuccess}
        onError={handleError}
        apiKey={googleKey}
        token={tokenState}
        iconOnly={iconOnly}
      />
      <FacebookConnectButton
        className={classNames(styles['social-connect__button'], buttonClassName, {
          [styles['social-connect__button--icon-only']]: iconOnly,
        })}
        siteLocation={siteLocation}
        onSuccess={handleSuccess}
        onError={handleError}
        iconOnly={iconOnly}
      />
    </div>
  )
}

export default SocialConnectButtons
