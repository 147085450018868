/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable no-undef */

/* globals FB: false */

function setupFacebook(apiKey) {
  if (typeof window === 'undefined') {
    return console.error('setupFacebook must be client side rendered')
  }

  window.fbAsyncInit = () => {
    FB.init({
      appId: apiKey,
      channelUrl: `//${window.location.host}/channel.html`,
      status: true,
      cookie: true,
      oauth: true,
      xfbml: true,
      version: 'v2.9',
    })
  }

  // prettier-ignore
  ;(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

export default setupFacebook
