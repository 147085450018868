import Session from 'types/Session'
import useMutation from '../useMutation'

const useFacebookSignIn = ({ onCompleted, onError }) => {
  return useMutation<{ session: Session }>('/auth/facebook/callback', '/api/v2/session', {
    keysToRevalidate: ['/api/v2/geolocation', '/api/v2/braintree_token'],
    extractData: ({ session }) => session,
    onCompleted,
    onError,
  })
}

export default useFacebookSignIn
