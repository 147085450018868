import notify from 'lib/errors'

const URL_BASE = 'https://media.everlane.com'
export default function getFilename(src: string): string {
  try {
    // @return {string} '/image/upload/c_fill,w_3840,ar_4:5,q_auto,dpr_1.0,f_auto,fl_progressive:steep/i/e36cf273_7391.jpg'
    const { pathname }: URL = new URL(src, URL_BASE)
    const splitPath = pathname.split('/')

    // these will always be the last two items in the array
    // @return {string} i/e36cf273_7391.jpg
    const folderAndFile = splitPath.slice(-2).join('/')

    // grab everthing before the file type, if one exists
    // @return {string} i/e36cf273_7391
    const publicId = folderAndFile.split('.')[0]

    return publicId
  } catch (error) {
    notify(new Error(`Cannot extract Cloudinary filename from ${src}`), { originalError: error })
    return ''
  }
}
