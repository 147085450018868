/* globals google */

import Cookie from 'js-cookie'
import Cookies from 'types/Cookies'

const ERROR = 'Google authentication failed. Please try again.'

function isFacebookWebview() {
  return (
    global.window.navigator.userAgent.includes('FBAN/FBIOS') ||
    global.window.navigator.userAgent.includes('Instagram')
  )
}

export default function googleConnect({ siteLocation = 'generic google', apiKey, token, signIn }) {
  // delete a cookie that prevents the google email select dialog from opening
  Cookie.remove('g_state')

  return new Promise((resolve, reject) => {
    if (isFacebookWebview()) {
      const params = {
        redirect_uri: `${window.location.origin}/auth/google_oauth2/callback`,
        response_type: 'code',
        scope: 'email profile',
        client_id: apiKey,
        fetch_basic_profile: true,
        state: token,
      }
      const queryParams = new URLSearchParams('')
      Object.keys(params).forEach(k => queryParams.append(k, params[k]))
      Cookie.set(Cookies.OAUTH_REDIRECT_PREVIOUS_PATH, window.location.pathname)
      window.location = `https://accounts.google.com/o/oauth2/v2/auth?${queryParams.toString()}`
      reject(new Error('Talking with Google…'))
      return
    }

    google.accounts.id.initialize({
      client_id: apiKey,
      scope: 'email profile',
      ux_mode: 'popup',
      auto_select: true,
      cancel_on_tap_outside: false,
      use_fedcm_for_prompt: true,
      callback: async response => {
        if (response && response.credential) {
          const signInResponse = await signIn({ ...response, siteLocation })

          if (signInResponse) {
            resolve(signInResponse.session)
          } else {
            reject(new Error(ERROR))
          }
        } else {
          reject(new Error(ERROR))
        }
      },
    })

    google.accounts.id.prompt()
  })
}
