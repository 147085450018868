import { useSWRConfig } from 'swr'

// Adapted from swr cache docs: https://swr.vercel.app/docs/advanced/cache

// swr stores its own internal state  in its cache alongside our data. Let’s make sure we aren’t
// updating those values.
function isInternalKey(key: string) {
  return key.startsWith('$')
}

// Returns a function that can be used to revalidate an swr item based on a regex match for the
// cache key
function useMatchMutate() {
  const { cache, mutate } = useSWRConfig()

  return (matcher, ...args) => {
    if (!(cache instanceof Map)) {
      throw new Error('matchMutate requires the cache provider to be a Map instance')
    }

    const keys = []

    Array.from(cache.keys()).forEach(key => {
      if (isInternalKey(key)) {
        return
      }

      if (matcher.test(key)) {
        keys.push(key)
      }
    })

    const mutations = keys.map(key => mutate(key, ...args))
    return Promise.all(mutations)
  }
}

export default useMatchMutate
