import { UserGender } from 'types/Gender'
import Visitor from 'types/Visitor'
import { patch } from '../fetchers/everlane'
import { UseMutationOptions } from '../types'
import useMutation from '../useMutation'
import useSession from './useSession'

type UpdateVisitorParams = {
  visitor: {
    email?: string
    gender?: UserGender
  }
  emailOptIn?: boolean
}

const useUpdateVisitor = (args: UseMutationOptions<Visitor, UpdateVisitorParams> = {}) => {
  const { data: session } = useSession()
  const visitorId = session?.visitor.sessionId

  return useMutation<Visitor, UpdateVisitorParams>(
    visitorId ? `/api/v2/visitors/${visitorId}` : null,
    null,
    {
      ...args,
      keysToRevalidate: args.keysToRevalidate || ['/api/v2/session'],
      fetcher: patch,
    },
  )
}

export default useUpdateVisitor
