import { useCallback } from 'react'
import useUpdateVisitor from 'lib/network/hooks/useUpdateVisitor'
import EmailAvailability from 'types/EmailAvailability'
import { get } from '../fetchers/everlane'
import useMutation from '../useMutation'

type EmailAvailabilityParams = {
  email: string
  emailOptin?: boolean
  siteLocation: string
}

type UseEmailAvailabilityParams = {
  onCompleted?: (availability: EmailAvailability) => void
  onError?: (error: Error) => void
}

const useEmailAvailability = ({ onCompleted, onError }: UseEmailAvailabilityParams) => {
  const { mutate: updateVisitor } = useUpdateVisitor({
    keysToRevalidate: ['/api/v2/session', '/api/v2/orders/preview'],
  })
  const { mutate, ...result } = useMutation<EmailAvailability, EmailAvailabilityParams>(
    '/api/v2/users/availability',
    null,
    {
      fetcher: get,
      keysToRevalidate: ['/api/v2/session'],
      onCompleted,
      onError,
    },
  )

  const checkEmailAvailability = useCallback(
    async (args: EmailAvailabilityParams) => {
      const emailAvailabilityMutation = await mutate({ emailOptin: args.emailOptin, ...args })
      await updateVisitor({ visitor: { email: args.email } })
      return emailAvailabilityMutation
    },
    [mutate, updateVisitor],
  )

  return { ...result, mutate: checkEmailAvailability }
}

export default useEmailAvailability
