import { useCallback, useState } from 'react'
import ApiError from 'lib/network/ApiError'
import { Params, FetchParams, UseMutationOptions } from './types'
import { post } from './fetchers/everlane'
import useMatchMutate from './useMatchMutate'

const identity = item => item

const useMutation = <T = any, P = Params>(
  url: string | ((args: P) => string),
  cacheKey: string | RegExp = null,
  {
    initialParams = {},
    initialFetchParams = {},
    formatParams = null,
    fetcher = post,
    keysToRevalidate = [],
    extractData = identity,
    skip = false,
    onCompleted,
    onError,
  }: UseMutationOptions<T> = {},
) => {
  const [isValidating, setIsValidating] = useState(false)
  const [error, setError] = useState<ApiError>()

  const matchMutate = useMatchMutate()

  const mutate = useCallback(
    async (params: Partial<P> = {}, fetchParams?: FetchParams) => {
      try {
        if (skip) return undefined

        setIsValidating(true)
        setError(undefined)

        const allParams = { ...initialParams, ...params } as P
        const formattedParams = formatParams ? formatParams(allParams) : allParams

        const allFetchParams = { ...initialFetchParams, ...fetchParams }
        const urlString = typeof url === 'string' ? url : url(allParams)

        let data: T

        if (typeof formattedParams === 'string') {
          data = await fetcher<T>(`${urlString}?${formattedParams}`, {}, allFetchParams)
        } else {
          data = await fetcher<T>(urlString, formattedParams, allFetchParams)
        }

        if (cacheKey) {
          await matchMutate(new RegExp(cacheKey), extractData(data), false)
        }

        await Promise.all(keysToRevalidate.map(k => matchMutate(new RegExp(k))))

        if (onCompleted) onCompleted(data)

        return data
      } catch (e) {
        setError(e)

        if (onError) onError(e)
      } finally {
        setIsValidating(false)
      }

      return null
    },
    [
      cacheKey,
      extractData,
      fetcher,
      formatParams,
      initialFetchParams,
      initialParams,
      keysToRevalidate,
      matchMutate,
      onCompleted,
      onError,
      skip,
      url,
    ],
  )

  return { mutate, error, isValidating }
}

export default useMutation
