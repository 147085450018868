import { useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Head from 'next/head'
import Button from '@everlane/button'

import useGoogleSignIn from 'lib/network/hooks/useGoogleSignIn'
import useKustomerLiveChat, {
  getKustomerAssistantId,
  isKustomerWidgetOpen,
} from 'lib/hooks/useKustomerLiveChat'

import { publishEvent } from 'lib/events/tracking'
import Image from 'components/core/Image'
import Event from 'types/Event'

import googleConnect from './connect'
import styles from './styles.module.scss'

const GOOGLE_ICON_URL = 'https://media.everlane.com/image/upload/v1644614726/i/icons8-google.svg'

const GoogleConnectButton = ({
  apiKey,
  token,
  className,
  siteLocation,
  iconOnly,
  onSuccess,
  onError,
}) => {
  const { asPath } = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const { handleStartKustomer } = useKustomerLiveChat({
    assistantId: getKustomerAssistantId(asPath),
    chatIcon: false,
    chatWindow: false,
  })

  const handleFail = err => {
    setIsLoading(false)
    onError(err)
  }
  const handleSuccess = ({ session }) => {
    const timestamp = Math.trunc(Date.now() / 1000).toString()
    setIsLoading(false)
    publishEvent(Event.User.SIGN_IN, {
      site_location: siteLocation,
      user: session?.user,
      visitor: session?.visitor,
      timestamp,
    })

    if (session?.user) {
      handleStartKustomer({
        chatIcon: false,
        chatWindow: isKustomerWidgetOpen(),
        user: session?.user,
      })
    }
    onSuccess()
  }

  const { mutate: signIn } = useGoogleSignIn({
    onCompleted: handleSuccess,
    onError: handleFail,
  })

  const handleClick = () => {
    setIsLoading(true)
    publishEvent(Event.Login.GOOGLE_BUTTON)

    return googleConnect({ siteLocation, apiKey, token, signIn })
      .then(handleSuccess)
      .catch(handleFail)
  }

  return (
    <>
      <Head>
        <meta name="referrer" content="strict-origin-when-cross-origin" />
      </Head>
      <Button
        type="button"
        variant={iconOnly ? 'secondary' : 'secondary-icon'}
        icon
        className={classNames(className, styles['google-connect__button'])}
        onClick={handleClick}
        disabled={isLoading}
      >
        <div className={styles['google-connect__cta']}>
          {!isLoading && (
            <Image
              src={GOOGLE_ICON_URL}
              alt="Log in with Google"
              containerClassName={styles['google-connect__cta-image']}
              aspectRatio={{ width: 1, height: 1 }}
              width={18}
              height={18}
              sizes="18px"
            />
          )}
          {!iconOnly && (
            <span className={styles['google-connect__cta-text']}>
              {isLoading ? 'Connecting' : 'Google'}
            </span>
          )}
        </div>
      </Button>
    </>
  )
}

GoogleConnectButton.propTypes = {
  apiKey: PropTypes.string,
  token: PropTypes.string,
  className: PropTypes.string,
  siteLocation: PropTypes.string,
  iconOnly: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
}

export default GoogleConnectButton
