import { buildUrl, setConfig } from 'cloudinary-build-url'
import getConfig from 'next/config'
import getFilename from 'lib/cloudinary'

export const initializeCloudinary = () => {
  setConfig({
    cloudName: 'everlane',
    secure: true,
    secureDistribution: 'media.everlane.com',
    privateCdn: true,
  })
}

initializeCloudinary()

const canUseAutoFormat = () => {
  const { publicRuntimeConfig } = getConfig() || {}
  const isProduction =
    process.env.NODE_ENV === 'production' && publicRuntimeConfig.RELEASE_STAGE === 'production'

  return isProduction
}

const defaultTransformations = {
  // We fetch jpgs in dev/staging because Cloudinary tries to send jp2s to mobile browsers
  // which makes things not work in Chrome dev tools.
  fetchFormat: canUseAutoFormat() ? 'auto' : 'jpg',
  // We use a dpr of 1.0 because we’re relying on srcset/sizes attributes to do image
  // selection. The browser is device-pixel-ratio aware and will pass doubled sizes for 2x
  // images and so on.
  dpr: '1.0',
  flags: 'progressive:steep',
  quality: 'auto',
}

export const createCloudinaryLoader = getTransformations => {
  return ({ urlSuffix, src, ...args }) =>
    buildUrl(getFilename(src), {
      cloud: { urlSuffix },
      transformations: { ...defaultTransformations, ...getTransformations(args) },
    })
}

export const cloudinaryAspectRatioCropLoader = createCloudinaryLoader(
  ({ aspectRatio, width, isZoomed }) => {
    return {
      crop: 'fill',
      quality: 'auto',
      aspectRatio: `${aspectRatio.width}:${aspectRatio.height}`,
      width: isZoomed ? width * 2 : width,
    }
  },
)
