import Session from 'types/Session'
import useMutation from '../useMutation'

// Hook for relaying data from Google Identity Services to Rails
// Sends a JWT to Rails. Receives a session.
const useGoogleSignIn = ({ onCompleted, onError }) => {
  return useMutation<{ session: Session }>('/auth/google_gis/callback', '/api/v2/session', {
    keysToRevalidate: ['/api/v2/geolocation', '/api/v2/braintree_token'],
    extractData: ({ session }) => session,
    onCompleted,
    onError,
  })
}

export default useGoogleSignIn
