// This file enumerates places on the site where users can log in or sign up.
// We publish these with our login-related events for data tracking purposes.

const SiteLocation = {
  GENERIC: 'generic',
  MOBILE_HOMEPAGE_MODAL: 'mobile homepage modal',
  COLLECTIONS_MODAL: 'collections modal',
  PRODUCTS_MODAL: 'products modal',
  WUNDERKIND_MODAL: 'wunderkind signup modal',
  PASSWORD_RESET: 'password reset',
  CHECKOUT: 'checkout',
  INTERNATIONAL_LANDING_PAGE: 'international landing page',
  FOOTER_EMAIL_FORM: 'footer email form',
} as const

type SiteLocationKey = keyof typeof SiteLocation
export type SiteLocationValue = (typeof SiteLocation)[SiteLocationKey]

const getSiteLocationForModals = (asPath: string): SiteLocationValue => {
  if (!asPath) return null

  if (asPath === '/') {
    return SiteLocation.MOBILE_HOMEPAGE_MODAL
  }
  if (asPath.includes('checkout')) {
    return SiteLocation.CHECKOUT
  }
  if (asPath.includes('collections')) {
    return SiteLocation.COLLECTIONS_MODAL
  }
  if (asPath.includes('products')) {
    return SiteLocation.PRODUCTS_MODAL
  }
  return SiteLocation.GENERIC
}

export { SiteLocation, getSiteLocationForModals }
